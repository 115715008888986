<template>
<div class="footer padded" :class="{hidden: $route.path.startsWith('/user') || $route.path === '/tvcode' }">
    <div class="description-socials">
        <div class="left-container">
            <div class="paragraph">
                <NuxtImg class="logo" src="/images/logo.svg" alt="Jackbox Games logo" />
            </div>
            <span class="sub-cta">{{ $t('FOOTER.SUBSCRIBE_CTA') }}</span>
            <span class="sub-description">{{ $t('FOOTER.SUBSCRIBE_CTA_DESC') }}</span>
            <EmailSignup
                subscriber-id="jackboxGamesNewsletter"
                :data-fields="[{ name: 'language', id: '4242', value: listrakLocale() }]"
                :button-text="$t('FORM.SUBSCRIBE')"
            />
        </div>
        <div class="right-container">
            <div class="spacer" />
            <div class="socials">
                <a :href="socialLink('fb', $i18n.locale)" target="_blank">
                    <span class="visually-hidden">Facebook</span>
                    <NuxtImg
                        class="icon" src="/images/socials/facebook.svg" alt="Facebook"
                        aria-hidden="true"
                        focusable="false"
                    />
                </a>
                <a :href="socialLink('insta', $i18n.locale)" target="_blank">
                    <span class="visually-hidden">Instagram</span>
                    <NuxtImg
                        class="icon" src="/images/socials/instagram.svg" alt="Instagram"
                        aria-hidden="true"
                        focusable="false"
                    />
                </a>
                <a href="https://www.reddit.com/r/jackboxgames/" target="_blank">
                    <span class="visually-hidden">Reddit</span>
                    <NuxtImg
                        class="icon" src="/images/socials/reddit.svg" alt="Reddit"
                        aria-hidden="true"
                        focusable="false"
                    />
                </a>
                <a :href="socialLink('tiktok', $i18n.locale)" target="_blank">
                    <span class="visually-hidden">TikTok</span>
                    <NuxtImg
                        class="icon" src="/images/socials/tiktok.svg" alt="TikTok"
                        aria-hidden="true"
                        focusable="false"
                    />
                </a>
                <a :href="socialLink('twitter', $i18n.locale)" target="_blank">
                    <span class="visually-hidden">Twitter</span>
                    <NuxtImg
                        class="icon" src="/images/socials/twitter.svg" alt="Twitter"
                        aria-hidden="true"
                        focusable="false"
                    />
                </a>
                <a href="https://www.youtube.com/jackboxgames" target="_blank">
                    <span class="visually-hidden">Youtube</span>
                    <NuxtImg
                        class="icon" src="/images/socials/youtube.svg" alt="Youtube"
                        aria-hidden="true"
                        focusable="false"
                    />
                </a>
            </div>
        </div>
    </div>
    <div class="copyright-links">
        <div class="copyright">© Copyright {{ new Date().getFullYear() }} Jackbox Games. All rights reserved.</div>

        <div class="links">
            <NuxtLink class="paragraph" to="/terms-of-service">{{ $t('FOOTER.TERMS_OF_SERVICE') }}</NuxtLink>
            <NuxtLink class="paragraph" to="/privacy-policy">{{ $t('FOOTER.PRIVACY_POLICY') }}</NuxtLink>
        </div>
    </div>
</div>
</template>

<script setup lang="ts">
import EmailSignup from '$components/forms/EmailSignup.vue'
import { I18n } from '~/services/i18n'

const i18n = useI18n()

function listrakLocale() {
    return I18n.getLocaleForListrak(i18n.locale.value)
}
// eslint-disable-next-line complexity
function socialLink(socialPlatform: string, locale: string): string {
    switch (locale) {
    case 'de':
        switch (socialPlatform) {
        case 'fb':
            return 'https://www.facebook.com/jackboxgamesde'
        case 'insta':
            return 'https://www.instagram.com/jackboxgamesde/'
        case 'tiktok':
            return 'https://www.tiktok.com/@jackboxgamesde'
        case 'twitter':
            return 'https://twitter.com/jackboxgamesde'
        default:
            console.warn(`social platform ${socialPlatform} is unknown for locale ${locale}`)
            return ''
        }
    case 'es':
    case 'es-XL':
    case 'es-419':
        switch (socialPlatform) {
        case 'fb':
            return 'https://www.facebook.com/jackboxgameslatam'
        case 'insta':
            return 'https://www.instagram.com/jackboxgameslatam/'
        case 'tiktok':
            return 'https://www.tiktok.com/@jackboxgameslatam'
        case 'twitter':
            return 'https://twitter.com/jackboxgameses'
        default:
            console.warn(`social platform ${socialPlatform} is unknown for locale ${locale}`)
            return ''
        }
    default:
        switch (socialPlatform) {
        case 'fb':
            return 'https://www.facebook.com/JackboxGames/'
        case 'insta':
            return 'https://www.instagram.com/playjackboxgames/'
        case 'tiktok':
            return 'https://www.tiktok.com/@playjackboxgames'
        case 'twitter':
            return 'https://twitter.com/jackboxgames'
        default:
            console.warn(`social platform ${socialPlatform} is unknown for locale ${locale}`)
            return ''
        }
    }
}
</script>

<style lang="scss" scoped>
@use "$styles/kit.scss" as *;

.footer{
    background: linear-gradient(180deg, rgba(0, 0, 0, 0.00) 0%, #000 100%);
    padding: 80px 0px;
    text-align: left;
    color: var(--neutral-100);

    &.hidden {
        display: none;
    }

    .left-container {
        display: flex;
        flex-direction: column;
        max-width: 630px;
        min-width: 300px;
        margin-right: 0px;
        @media screen and (max-width: $mq-cutoff-small) {
            width: 100%;
        }
    }

    .right-container {
        height: 200px;
        display: flex;
        flex-direction: column;
        align-items: stretch;
        @media screen and (max-width: $mq-cutoff-small) {
            height: auto;
            margin-top: 40px;
        }
    }

    .spacer {
        flex: 1;
    }

    :deep(.email-form) {
        display: flex;
        flex-direction: row;
        margin-top: 16px;
        width: 100%;
        height: 48px;

        .field {
            width: auto;
        }

        @media screen and (max-width: $mq-cutoff-small) {
            justify-content: center !important;
            flex-direction: column !important;
        }
    }

    :deep(.email-input) {
        width: 100%;
        margin-bottom: 0px;
        height: 48px;
    }

    :deep(.input) {
        height: 48px;
        border-width: 0px 0px 2px 0px;
    }

    :deep(.email-icon) {
        padding-top: 15px;
        padding-left: 10px;
        position: absolute;
        z-index: 1;
        width: 16px;
    }

    :deep(.submit-btn) {
        display: flex;
        width: 140px;
        height: 48px;
        margin-left: 16px;
        margin-top: 0px;
        justify-content: center;
        align-items: center;
        border-radius: 4px;
        border: none;
        background: var(--Primary-200, #B2BBFF);
        @media screen and (max-width: $mq-cutoff-small) {
            width: 100%;
            margin-left: 0px;
            margin-top: 10px;
        }
    }

    .sub-cta {
        font-size: 16px;
        font-weight: bold;
        margin-top: 36px;
    }

    .sub-description {
        margin-top: 8px;
        text-wrap: wrap;
        max-width: 630px;
        min-width: 300px;
        @media screen and (max-width: $mq-cutoff-small) {
            margin-bottom: 20px;
        }
    }

    .logo {
        height: 40px;
    }

    .description-socials {
        border-bottom: 1px solid var(--primary-100);
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        justify-content: space-between;
        align-items: center;
        padding-bottom: 20px;
        @media screen and (max-width: $mq-cutoff-small) {
            display: flex !important;
            justify-content: center !important;
            flex-direction: column !important;
        }

        .socials {
            min-width: 288px;
            .icon {
                height: 24px;
                margin-left: 24px;
                width: 24px;
            }
        }
    }

    .copyright-links {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        padding-top: 20px;
        .links {
            a {
                color: var(--neutral-50);
                margin-left: 32px;
                text-decoration: none;
            }
        }
    }

    @include mq-small-and-less {
        padding: 32px 0px;
        text-align: center;

        .logo, .paragraph {
            font-size: 14px;
            margin-bottom: 5px;
        }

        .copyright-links, .description-socials {
            justify-content: center;
            display: block;
        }

        .copyright-links {
            flex-wrap: wrap-reverse;

            .links {
                display: flex;
                justify-content: space-evenly;
                margin-bottom: 21px;
                width: 100%;
                a {
                    font-size: 14px;
                    margin: 0px;
                }
            }

            .copyright {
            margin-bottom: 10px;
                font-size: 12px;
            }
        }
    }
}
</style>
